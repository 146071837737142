import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Page from '../../src/components/Page';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

import '../../src/scss/react-images.scss';
import '../../src/scss/slick-slider.scss';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="shortcut icon"
          href="https://ert.credit/favicon.ico"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>ert.credit, The fastest way to get your Employee Retention Tax Credit</title>
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="If you are a small business or non profit with fewer than 500 employees, and you were
          severely affected by Covid-19. Despite the shutdowns and other issues, if you paid your employees through
          multiple quarters you are entitled to the Employee Retention Tax Credit. We are here to help you quickly
          and easily get your tax credit."
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public-images%2Fsocial-screenshot.png?alt=media&token=1e974939-9044-4a84-b068-a72e433e977b"
        />
        <meta
          property="og:title"
          content="ERT.credit: The fastest and easiest way to get your Employee Retention Tax Credit."
        />
        <meta
          property="og:description"
          content="If you are a small business or non profit with fewer than 500 employees, and you were
          severely affected by Covid-19. Despite the shutdowns and other issues, if you paid your employees through
          multiple quarters you are entitled to the Employee Retention Tax Credit. We are here to help you quickly
          and easily get your tax credit."
        />
        <meta
          property="og:url"
          content="https://ert.credit/"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Page>
        {props.children}
      </Page>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
